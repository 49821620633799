import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-lindsey',
  templateUrl: './lindsey.component.html',
  styleUrls: ['./lindsey.component.css']
})
export class LindseyComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}

import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-snap',
  templateUrl: './snap.component.html',
  styleUrls: ['./snap.component.css']
})
export class SnapComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
